import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const PrivateRoute = ({ children, user, ...rest }) => {
  // console.log("user", user);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user.token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state) => {
  // console.log("state", state);

  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(PrivateRoute);
