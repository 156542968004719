import React, { useEffect, useCallback } from "react";
import moment from "moment";
import * as AlunoService from "../../services/aluno";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { store } from "../../redux/store";
import * as action from "../../redux/actions";
import { connect } from "react-redux";
import EFaixa from "../../enums/faixa";

import ReactGA from "react-ga4";

const useStyles = makeStyles((theme) => ({
  titulo: {
    padding: "16px 0 0",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    padding: "16px",
  },
  turmas: {
    padding: "0 0 16px 0",
    "& span": {
      paddingLeft: "8px",
      textTransform: "capitalize",
    },
    "& .aula": {
      marginTop: "8px",
    },
  },
}));

function Perfil(props) {
  if (window.location.host === "aluno.academiapedromartinez.com.br")
    ReactGA.send({
      hitType: "pageview",
      page: window.location.hash,
      title: "Perfil",
    });

  const { user } = props;
  const classes = useStyles();
  const dateFormat = "YYYY-MM-DD";
  const hoje = moment().format(dateFormat);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    // console.log(data);

    // Remove dados não editaveis
    delete data.plano_nome;
    delete data.vencimento;

    const result = await AlunoService.atualizarPerfil(data);

    if (result) {
      store.dispatch(
        action.showAlert({
          message: "Atualizados",
          type: "success",
          title: "Dados Pessoais",
        })
      );
    }
  };

  const getPerfil = useCallback(async () => {
    let perfil = await AlunoService.getPerfil();

    setValue("apelido", perfil.apelido);
    setValue("nome", perfil.nome);
    setValue("bairro", perfil.bairro);
    setValue("celular", perfil.celular);
    setValue("cpf", perfil.cpf);
    setValue("cep", perfil.cep);
    setValue("email", perfil.email);
    setValue("endereco", perfil.endereco);
    setValue("rg", perfil.rg);
    setValue("telefone", perfil.telefone);
    setValue("vencimento", perfil.vencimento);
    setValue("plano_nome", perfil.plano_nome);

    if (perfil.faixa !== null) {
      perfil.faixa = perfil.faixa.toString();

      setValue("faixa", perfil.faixa);
    }

    if (perfil.grau !== null) {
      perfil.grau = perfil.grau.toString();

      setValue("grau", perfil.grau);
    }

    if (perfil.nascimento) {
      const data = perfil.nascimento.split("T")[0];

      setValue("nascimento", data);
    }
  }, [setValue]);

  useEffect(() => {
    getPerfil();
  }, [getPerfil, user]);

  return (
    <Container component="main" maxWidth="xs">
      <Typography component="h1" variant="h5" className={classes.titulo}>
        Perfil do Aluno
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper className={classes.paper}>
          <Typography variant="h6" gutterBottom>
            Informações Pessoais
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="nome"
                defaultValue=""
                control={control}
                // rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    // required
                    disabled
                    aria-required
                    label="Nome Completo"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="apelido"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Apelido"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="nascimento"
                defaultValue=""
                control={control}
                // rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    type="date"
                    fullWidth
                    // required
                    disabled
                    aria-required
                    label="Data de Nascimento"
                    variant="outlined"
                    margin="normal"
                    format={null}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: hoje,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="peso"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    type="number"
                    fullWidth
                    aria-required
                    label="Peso"
                    variant="outlined"
                    margin="normal"
                    format={null}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: "0.01",
                      max: 999.99,
                      min: 0,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="cpf"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="CPF"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="rg"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="RG"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Typography variant="h6" gutterBottom>
            Contato
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="cep"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="CEP"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="endereco"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Endereço"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="bairro"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Bairro"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="email"
                defaultValue=""
                control={control}
                // rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    // required
                    disabled
                    // aria-required
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="celular"
                defaultValue=""
                control={control}
                // rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    // required
                    disabled
                    // aria-required
                    label="Celular"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="telefone"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Telefone"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Typography variant="h6" gutterBottom>
            Graduação
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="faixa"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  if (errors.faixa) {
                    field.error = true;
                  }
                  return (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      // required
                      error={errors.faixa ? true : false}
                    >
                      <InputLabel id="faixa-label">Faixa</InputLabel>
                      <Select
                        labelId="faixa-label"
                        id="faixa"
                        label="Faixa"
                        displayEmpty
                        // required
                        disabled
                        fullWidth
                        {...field}
                      >
                        <MenuItem value=""></MenuItem>

                        {EFaixa.map((item, key) => {
                          return (
                            <MenuItem key={key} value={item.id}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  );
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="grau"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  if (errors.grau) {
                    field.error = true;
                  }
                  return (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      // required
                      error={errors.grau ? true : false}
                    >
                      <InputLabel id="grau-label">Grau(s)</InputLabel>
                      <Select
                        labelId="grau-label"
                        id="grau"
                        label="Grau(s)"
                        displayEmpty
                        fullWidth
                        // required
                        disabled
                        {...field}
                      >
                        <MenuItem value=""></MenuItem>
                        <MenuItem value="0">0</MenuItem>
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem>
                        <MenuItem value="4">4</MenuItem>
                      </Select>
                    </FormControl>
                  );
                }}
              />
            </Grid>
          </Grid>

          <Typography variant="h6" gutterBottom>
            Financeiro
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="vencimento"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    disabled
                    label="Dia de Vencimento"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="plano_nome"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    disabled
                    label="Plano"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Salvar
          </Button>
        </Paper>
      </form>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(Perfil);
