import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";

const LoaderWrapper = styled.div`
  background: rgba(256, 256, 256, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const Loader = (props) => {
  const { visible } = props;

  return visible ? (
    <LoaderWrapper>
      <CircularProgress />
    </LoaderWrapper>
  ) : null;
};

export default Loader;
