import { api } from "../utils/api";

export const getPerfil = async () => {
  // console.log('getPerfil');

  const response = await api.get("/aluno/perfil");
  // console.log('response', response.data);
  return response.data;
};

export const atualizarPerfil = async (params) => {
  const response = await api.put("/aluno/perfil", params);

  return response.data;
};

export const getTurmas = async () => {
  // console.log('getPerfil');

  const response = await api.get("/aluno/turmas");
  // console.log('response', response.data);
  return response.data;
};
