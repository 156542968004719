import { api } from "../utils/api";

export const getByAluno = async () => {
  // console.log('getPerfil');

  const response = await api.get("/aluno/ficha-saude");
  // console.log('response', response.data);
  return response.data;
};

export const create = async (data) => {
  // console.log('getPerfil');

  const response = await api.post("/aluno/ficha-saude", data);
  // console.log('response', response.data);
  return response.data;
};

export const update = async (data) => {
  // console.log('getPerfil');

  const response = await api.put("/aluno/ficha-saude", data);
  // console.log('response', response.data);
  return response.data;
};
