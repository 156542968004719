import React, { useState, useCallback, useEffect } from "react";

import {
  CardContent,
  Card,
  Container,
  Typography,
  Box,
  InputLabel,
  Select,
  MenuItem,
  Button,
  CardActions,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import "moment/min/locales";
import * as AgendamentoService from "../../services/agendamento";
import * as AulaServices from "../../services/aulas";
import DoneIcon from "@material-ui/icons/Done";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import BlockIcon from "@material-ui/icons/Block";
import ScheduleIcon from "@material-ui/icons/Schedule";
import CachedIcon from "@material-ui/icons/Cached";
import EStatus from "../../enums/status";
import { connect } from "react-redux";
import { store } from "../../redux/store";
import * as action from "../../redux/actions";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AGENDAMENTO_STATUS from "../../enums/agendamento-status";
import ReactGA from "react-ga4";

moment.locale("pt-br");

const useStyles = makeStyles(() => ({
  datawrapper: {
    marginBottom: "16px",
  },
  data: {
    width: "100%",
    textTransform: "capitalize",
  },
  option: {
    textTransform: "capitalize",
  },
  titulo: {
    padding: "16px 0",
  },
  button: {
    "& + button": {
      marginTop: "16px",
    },
    "& div": {
      width: "100%",
    },
    "&.Aguardando": {
      borderColor: "#ffcd38",
      color: "#fff",
      backgroundColor: "#ffcd38",
    },
    "&.Negado": {
      opacity: ".5",
      borderColor: "#aa2e25",
      color: "#fff",
      backgroundColor: "#aa2e25",
      "&:disabled": {
        backgroundColor: "#aa2e25",
      },
    },
    "&.Confirmado": {
      borderColor: "#357a38",
      color: "#fff",
      backgroundColor: "#357a38",
    },
    "&:disabled": {
      opacity: ".5",
    },
  },
  actions: {
    alignItems: "center",
    justifyContent: "flex-end",
  },
  description: {
    display: "block",
    textAlign: "center",
    fontSize: "12px",
    textTransform: "capitalize",
  },
}));

const Agendamento = (props) => {
  // console.log(props);

  if (window.location.host === "aluno.academiapedromartinez.com.br")
    ReactGA.send({
      hitType: "pageview",
      page: window.location.hash,
      title: "Agendamento",
    });

  const classes = useStyles();
  const maxDays = 7;
  const dateFormat = "YYYY-MM-DD";
  const hoje = moment().format(dateFormat);
  const dates = [];

  for (let index = 0; index < maxDays; index++) {
    dates.push(moment().add(index, "days").format(dateFormat));
  }

  const [data, setData] = useState(dates[0]);
  const [treinos, setTreinos] = useState();
  const [open, setOpen] = useState(!window.localStorage.getItem("modalAgreed"));

  const handleClose = (_event, reason) => {
    if (reason === "agreeClick") {
      window.localStorage.setItem("modalAgreed", "true");

      setOpen(false);
    }
  };

  const agendar = async (props) => {
    // console.log("agendar", props);
    const result = await AgendamentoService.Agendar(props);

    if (result) {
      if (window.location.host === "aluno.academiapedromartinez.com.br")
        ReactGA.event({
          category: "Agendamento",
          action: "Agendou",
          label: props.aula_id,
        });
      store.dispatch(
        action.showAlert({
          message: "Realizado com Sucesso",
          type: "success",
          title: "Check-in",
        })
      );
    }

    getDisponiveis({ data: props.data, hoje });
  };

  const cancelar = async (props) => {
    // console.log("cancelar", props);

    const result = await AgendamentoService.Cancelar(props);

    if (result) {
      if (window.location.host === "aluno.academiapedromartinez.com.br")
        ReactGA.event({
          category: "Agendamento",
          action: "Cancelou",
        });

      store.dispatch(
        action.showAlert({
          message: "Cancelado com sucesso",
          type: "success",
          title: "Check-in",
        })
      );
    }

    getDisponiveis({ data, hoje });
  };

  const getIconByStatus = (status) => {
    switch (status) {
      case 0:
        return <DoneIcon />;
      case 1:
        return <BlockIcon />;
      case 2:
        return <DoneAllIcon />;
      default:
        return <ScheduleIcon />;
    }
  };

  const getDisponiveis = useCallback(async (params) => {
    setTreinos();

    const { data, hoje } = params;

    const agendamentos = await AgendamentoService.getAgendamentos({ data });

    const horaLimiteAgendamento = moment()
      .subtract("minutes", 15)
      .format("HH:mm");

    let treinos = await AulaServices.getAulasDisponiveis({ data });

    treinos = treinos.filter((treino) => {
      // TODO: Mudar as refenrencias de treino para aula

      agendamentos.map((agendamento) => {
        if (treino.id === agendamento.aula_id) {
          treino.agendamento = agendamento;
        }

        return agendamento;
      });

      if (treino?.agendamento?.status === AGENDAMENTO_STATUS.NEGADO) {
        treino.disabled = true;
      }

      if (
        moment(hoje).isSame(moment(data), "day") &&
        treino.horario < horaLimiteAgendamento
      ) {
        if (treino?.agendamento?.status === AGENDAMENTO_STATUS.CONFIRMADO) {
          // treino.disabled = true;

          return treino;
        } else {
          return false;
        }
      } else {
        return treino;
      }
    });

    setTreinos(treinos);
  }, []);

  useEffect(() => {
    getDisponiveis({ data, hoje });
  }, [data, hoje, getDisponiveis]);

  return (
    <Container component="main" maxWidth="xs">
      <Typography component="h1" variant="h5" className={classes.titulo}>
        Check-in
      </Typography>

      <Dialog
        disableEscapeKeyDown={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="modal-message"
      >
        <DialogTitle id="alert-dialog-title">{"Atenção"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            A partir do dia <b>15/08/2022</b> será obrigatório o uso do{" "}
            <b>Kimono</b> padrão da equipe <b>Tetris</b> em todos os treinos,
            para evitar transtornos e inconvenientes, atente-se as regras da
            academia, bons treinos!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => handleClose(e, "agreeClick")}
            color="primary"
            autoFocus
            id="bt-modal-ok"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Box className={classes.datawrapper}>
        <InputLabel id="data-label">Data</InputLabel>
        <Select
          className={classes.data}
          labelId="data-label"
          id="data"
          value={data}
          onChange={(event) => {
            if (window.location.host === "aluno.academiapedromartinez.com.br")
              ReactGA.event({
                category: "Agendamento",
                action: "Mudou de Data",
                label: moment(event.target.value).format("DD/MM/yyy"),
              });
            setData(event.target.value);
          }}
        >
          {dates.map((date, key) => {
            return (
              <MenuItem className={classes.option} value={date} key={key}>
                {moment(date).format("dddd, DD/MM/yyy")}
              </MenuItem>
            );
          })}
        </Select>
      </Box>

      <Box mt={2}>
        <Card>
          <CardContent>
            {treinos ? (
              treinos.length > 0 ? (
                treinos.map((treino, key) => {
                  return (
                    <Button
                      key={key}
                      data-treino-id={treino.id}
                      variant="contained"
                      color="default"
                      disabled={treino.disabled}
                      fullWidth
                      size="large"
                      className={`${
                        treino?.agendamento?.status
                          ? EStatus[treino?.agendamento?.status]
                          : ""
                      } ${classes.button} schedule-button`}
                      endIcon={getIconByStatus(treino?.agendamento?.status)}
                      onClick={() => {
                        if (treino.agendamento) {
                          cancelar({ id: treino.agendamento.id });
                        } else {
                          agendar({ data, aula_id: treino.id });
                        }
                      }}
                    >
                      <Box>
                        <span>{`${treino.horario} - ${treino.nome}`}</span>
                      </Box>
                    </Button>
                  );
                })
              ) : (
                <Alert severity="warning">Não há treinos disponiveis</Alert>
              )
            ) : (
              <Alert severity="info">
                Verificando se há treinos disponiveis
              </Alert>
            )}
          </CardContent>
          <CardActions className={classes.actions}>
            <IconButton
              onClick={() => getDisponiveis({ data, hoje })}
              aria-label="Atualizar"
            >
              <CachedIcon />
            </IconButton>
          </CardActions>
        </Card>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
  };
};

export default connect(mapStateToProps, null)(Agendamento);
