import React, { useEffect, useCallback } from "react";

import * as FichaSaudeService from "../../services/ficha-saude.service";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Container,
  Typography,
  Grid,
  Button,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { store } from "../../redux/store";
import * as action from "../../redux/actions";
import { connect } from "react-redux";
import ReactGA from "react-ga4";
import { MaskedTextField } from "../../components";

const useStyles = makeStyles((theme) => ({
  titulo: {
    padding: "16px 0 0",
    marginBottom: "8px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    padding: "16px",
  },
}));

function FichaSaude(props) {
  if (window.location.host === "aluno.academiapedromartinez.com.br")
    ReactGA.send({
      hitType: "pageview",
      page: window.location.hash,
      title: "Ficha de Saúde",
    });

  const { user } = props;
  const classes = useStyles();

  const { handleSubmit, control, setValue } = useForm();

  const onSubmit = async (data) => {
    // console.log(data);

    data.id = data.ficha_id;
    delete data.ficha_id;

    const result = data.id
      ? await FichaSaudeService.update(data)
      : await FichaSaudeService.create(data);

    // console.log(result);

    if (result) {
      store.dispatch(
        action.showAlert({
          message: "Atualizados",
          type: "success",
          title: "Ficha de Saúde",
        })
      );
    }
  };

  const getFichaSaude = useCallback(async () => {
    let fichaSaude = await FichaSaudeService.getByAluno();

    // console.log(fichaSaude);

    setValue("ficha_id", fichaSaude.id);
    setValue("plano_nome", fichaSaude.plano_nome);
    setValue("plano_carteirinha", fichaSaude.plano_carteirinha);
    setValue("hospital_nome", fichaSaude.hospital_nome);
    setValue("contato_1_nome", fichaSaude.contato_1_nome);
    setValue("contato_1_telefone", fichaSaude.contato_1_telefone);
    setValue("contato_2_nome", fichaSaude.contato_2_nome);
    setValue("contato_2_telefone", fichaSaude.contato_2_telefone);
    setValue("doenca_cronica", fichaSaude.doenca_cronica);
    setValue("alergia", fichaSaude.alergia);
    setValue("medicamento", fichaSaude.medicamento);
  }, [setValue]);

  useEffect(() => {
    getFichaSaude();
  }, [getFichaSaude, user]);

  return (
    <Container component="main" maxWidth="xs">
      <Typography component="h1" variant="h5" className={classes.titulo}>
        Ficha de Saúde
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper className={classes.paper}>
          <Typography variant="h6" gutterBottom>
            Plano de Saúde
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="plano_nome"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Nome do Plano"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="plano_carteirinha"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Número da Carteirinha"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Typography variant="h6" gutterBottom>
            Hospital de Referência
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="hospital_nome"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    aria-required
                    required
                    fullWidth
                    label="Nome do Hospital"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Typography variant="h6" gutterBottom>
            Contatos em Caso de Emergência
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="contato_1_nome"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    aria-required
                    required
                    fullWidth
                    label="Nome do Contato 1"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="contato_1_telefone"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <MaskedTextField
                    mask="(99) 99999-9999"
                    placeholderChar={"_"}
                    aria-required
                    required
                    fullWidth
                    label="Telefone do Contato 1"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="contato_2_nome"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    aria-required
                    required
                    fullWidth
                    label="Nome do Contato 2"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="contato_2_telefone"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <MaskedTextField
                    mask="(99) 99999-9999"
                    placeholderChar={"_"}
                    aria-required
                    required
                    fullWidth
                    label="Telefone do Contato 2"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Typography variant="h6" gutterBottom>
            Informações Médicas
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="doenca_cronica"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Doenças Crônicas"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="alergia"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Alergias"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="medicamento"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Medicamentos em Uso"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Salvar
          </Button>
        </Paper>
      </form>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(FichaSaude);
