import { api } from "../utils/api";

export const getProdutos = async () => {
  const response = await api.get("/produtos", {
    params: {
      status: 1,
    },
  });

  return response.data;
};
