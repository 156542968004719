import { api } from "../utils/api";
import ReactGA from "react-ga4";
export const postChat = async (params) => {
  // console.log('getDisponiveis', params);
  if (window.location.host === "aluno.academiapedromartinez.com.br")
    ReactGA.event({
      category: "Chat",
      action: "Perguntou",
      label: "Perguntou",
    });

  const response = await api.post("/aluno/chat", { prompt: params });

  return response.data;
};
