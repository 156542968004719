import { api } from "../utils/api";

export const SolicitarNovaSenha = async (params) => {
  const response = await api.post(
    "/autenticacao/aluno/solicitar-nova-senha",
    params
  );

  return response.data;
};

export const SalvarSenha = async (params, token) => {
  // console.log('SalvarSenha', params, token);

  const response = await api.post("/aluno/salvar-senha", params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const Login = async (params) => {
  const response = await api.post("/autenticacao/aluno/login", params);

  return response.data;
};

export const LoginSocial = async (params) => {
  const response = await api.post("/autenticacao/aluno/login-social", params);

  return response.data;
};
