import React from 'react';
import pkgJson from '../../../package.json';
import { Container, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { Facebook, Instagram, YouTube, WhatsApp } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    footer: {
        padding: '32px',
        background: '#f6f6f6',
        color: '#aaa',
        'text-align': 'center',
        "& a": {
            transition: 'color 0.2s ease-in-out',
            color: '#aaa',
            'text-decoration': 'none',
            "&:active, &:hover": {
                color: '#666'
            }
        },
        "& h6": {
            color: "#484848"
        },
        [theme.breakpoints.down("sm")]: {
            margin: theme.spacing(3, 0, 0),
            padding: theme.spacing(2),
            "& a.icon": {
                margin: '0 8px',
                "& svg": {
                    'font-size': '32px'
                }
            }
        },
        [theme.breakpoints.up("sm")]: {
            "& a.icon": {
                margin: '0 16px',
                "& svg": {
                    'font-size': '48px'
                }
            }
        }
    }
}));

const Footer = () => {
    const classes = useStyles();

    return <Box component="footer" mt={6} className={classes.footer}>
        <Container>

            {/* <Container fixed={true} >
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={6}>
                        <Typography component="h6">Endereço</Typography>

                        <Box>
                            <a target="_blank" rel="noopener noreferrer" href="http://maps.google.com/?q=Academia Pedro Martinez">
                                Estrada de Itapecerica 3285, sobreloja <br />
                                Jd. Germânia, CEP: 05835-005 <br />
                                São Paulo - SP</a>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography component="h6">Contato</Typography>

                        <Box>
                            <a target="_blank" rel="noopener noreferrer" href="tel:(11) 98870-1860">Tel: (11) 98870-1860</a><br />
                            <a target="_blank" rel="noopener noreferrer" href="https://wa.me/5511988701860?text=Contato%20pelo%20site%20">WhatsApp: (11) 98870-1860</a>
                        </Box>
                    </Grid>
                </Grid>
            </Container> */}

            <Container fixed={true}>
                {/* <Box mt={4}>
                    <Typography id="contato" component="h6">Fale conosco pelas nossas redes sociais</Typography>

                    <Box m={2}>
                        <a target="_blank" rel="noopener noreferrer" href="https://web.facebook.com/academiapedromatinez/"
                            className="icon fa-facebook"><Facebook /></a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCuWhFMEY8wR1sR5wD6UNhlQ"
                            className="icon fa-youtube"><YouTube /></a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/pedromartinezteam/"
                            className="icon fa-instagram"><Instagram /></a>
                        <a target="_blank" rel="noopener noreferrer" href="https://wa.me/5511988701860?text=Contato%20pelo%20site%20"
                            className="icon fa-whatsapp"><WhatsApp /></a>
                    </Box>
                </Box> */}

                <Box>
                    &copy; Academia Pedro Martinez <br />
                    Dev.: <a href="mailto:tiloreboucas@gmail.com">Tilo Rebouças</a><br />
                    v. {pkgJson.version}
                </Box>
            </Container>
        </Container>
    </Box>
}

export default Footer;
