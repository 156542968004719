import React from "react";
import * as Autenticacao from "../../services/autenticacao";
import { useForm, Controller } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import ReactGA from "react-ga4";
import { store } from "../../redux/store";
import * as action from "../../redux/actions";

import {
  Container,
  TextField,
  Typography,
  Box,
  Button,
} from "@material-ui/core";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

export default function SalvarSenha() {
  if (window.location.host === "aluno.academiapedromartinez.com.br")
    ReactGA.send({
      hitType: "pageview",
      page: window.location.hash,
      title: "Salvar Senha",
    });

  let { token } = useParams();
  let history = useHistory();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    // console.log("onSubmit", data);

    const response = await Autenticacao.SalvarSenha(data, token);

    // console.log(response);

    if (response) {
      store.dispatch(
        action.showAlert({
          message: "Salva com sucesso",
          type: "success",
          title: "Nova Senha",
        })
      );

      history.replace({ pathname: "/" });
    } else {
      store.dispatch(
        action.showAlert({
          message: "Não foi possivel salvar nova senha",
          type: "danger",
          title: "Nova Senha",
        })
      );
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box mt={2}>
        <Typography component="h1" variant="h5">
          Nova Senha
        </Typography>

        <Box component="p">Cadastre sua nova senha</Box>

        <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
          <Controller
            name="senha"
            control={control}
            rules={{ required: "Campo Obrigatório" }}
            error={true}
            render={({ field }) => {
              if (errors.senha) {
                field.error = true;
                field.helperText = errors.senha.message;
              }

              return (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  defaultValue=""
                  label="Nova Senha"
                  type="password"
                  autoFocus
                  {...field}
                />
              );
            }}
          />

          <Controller
            name="confirmaSenha"
            control={control}
            rules={{ required: "Campo Obrigatório" }}
            render={({ field }) => {
              if (errors.confirmaSenha) {
                field.error = true;
                field.helperText = errors.confirmaSenha.message;
              }

              return (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Confirmar Senha"
                  type="password"
                  defaultValue=""
                  {...field}
                />
              );
            }}
          />

          <Button type="submit" fullWidth variant="contained" color="primary">
            Salvar
          </Button>
        </form>

        <Box mt={3}>
          <Button startIcon={<ArrowBackIcon />} href="#/login">
            Voltar para o Login
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
