import React, { useEffect, useState } from "react";

import * as AlunoService from "../../services/aluno";

import { Container, Typography, Paper, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import ReactGA from "react-ga4";
import SEMANA from "../../enums/semana";

const useStyles = makeStyles((theme) => ({
  titulo: {
    padding: "16px 0 0",
    marginBottom: "8px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    padding: "16px",
    marginBottom: "16px",
  },
  turma: {
    fontSize: "22px",
    textAlign: "center",
    paddingBottom: "8px",
  },
  professor: {
    fontSize: "20px",
    textAlign: "center",
    paddingTop: "8px",
  },
  aula: {
    marginBottom: "8px",
  },
}));

function Turmas() {
  if (window.location.host === "aluno.academiapedromartinez.com.br")
    ReactGA.send({
      hitType: "pageview",
      page: window.location.hash,
      title: "Turmas",
    });

  const classes = useStyles();

  const [turmas, setTurmas] = useState();

  useEffect(() => {
    const getTurmas = async () => {
      const turmas = await AlunoService.getTurmas();

      setTurmas(turmas);
    };

    getTurmas();
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <Typography component="h1" variant="h5" className={classes.titulo}>
        Turmas
      </Typography>

      {turmas && (
        <>
          {turmas.map((turma) => {
            return (
              <Paper className={classes.paper} key={turma.id}>
                <Typography className={classes.turma}>{turma.nome}</Typography>
                <Divider />
                <Typography className={classes.professor}>
                  {turma.professor_nome}
                </Typography>
                <ul>
                  {turma.aulas.map((aula) => {
                    return (
                      <li
                        key={turma.id + "_" + aula.id}
                        className={classes.aula}
                      >
                        <strong>{SEMANA[aula.dia]}</strong>
                        {" - "}
                        <span>{aula.horario}</span>
                      </li>
                    );
                  })}
                </ul>
                {/* <List className="aulas">
                  {turma.aulas.map((aula) => {
                    return (
                      <ListItem className="aula" key={turma.id + "_" + aula.id}>
                        <ListItemText
                          primary={SEMANA[aula.dia]}
                          secondary={aula.horario}
                        />
                      </ListItem>
                    );
                  })}
                </List> */}
              </Paper>
            );
          })}
        </>
      )}
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(Turmas);
