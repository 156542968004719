import React, { useEffect } from "react";
import { Container, Typography, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as HealthService from "../../services/health";
import ReactGA from "react-ga4";

const useStyles = makeStyles((theme) => ({
  titulo: {
    padding: "16px 0 0",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    padding: "16px",
  },
  nome: {
    textTransform: "capitalize",
  },
}));

function Manutencao() {
  if (window.location.host === "aluno.academiapedromartinez.com.br")
    ReactGA.send({
      hitType: "pageview",
      page: window.location.hash,
      title: "Manutenção",
    });

  const classes = useStyles();

  useEffect(() => {
    const interval = setInterval(() => {
      // Verifica o estado da saúde
      HealthService.Check()
        .then(() => {
          window.location.href = "#/login";
        })
        .catch((error) => {
          console.error("Erro na verificação de saúde:", error);
        });
    }, 10000); // Verifica a cada 30 segundos

    // Limpa o intervalo quando o componente é desmontado
    return () => clearInterval(interval);
  }, []); // O segundo argumento vazio indica que este efeito é executado apenas uma vez

  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5" className={classes.titulo}>
          Em Manutenção
        </Typography>
        <p>Olá, Estamos trabalhando para retornar o mais rápido possível.</p>
      </Paper>
    </Container>
  );
}

export default Manutencao;
