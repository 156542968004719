import { combineReducers } from "redux"
import loading from './loading';
import alert from './alert.reducer';
import user from './user.reducer.js';

const rootReducer = combineReducers({
    loading: loading,
    alert: alert,
    user: user
})

export default rootReducer
