import React, { useState, useEffect } from "react";
import Routes from "./routes";
import { Loader, Alert, Footer } from "./components";
import { connect } from "react-redux";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Button } from "@material-ui/core";

function useAddToHomescreenPrompt() {
  const [prompt, setState] = useState(null);

  const promptToInstall = () => {
    if (prompt) {
      return prompt.prompt();
    }
    return Promise.reject(
      new Error(
        'Tried installing before browser sent "beforeinstallprompt" event'
      )
    );
  };

  useEffect(() => {
    const ready = (e) => {
      e.preventDefault();
      setState(e);
    };

    window.addEventListener("beforeinstallprompt", ready);

    return () => {
      window.removeEventListener("beforeinstallprompt", ready);
    };
  }, []);

  return [prompt, promptToInstall];
}

export function InstallButton() {
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const [isVisible, setVisibleState] = React.useState(false);

  React.useEffect(() => {
    if (prompt) {
      setVisibleState(true);
    }
  }, [prompt]);

  if (!isVisible) {
    return null;
  }

  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      endIcon={<GetAppIcon />}
      onClick={promptToInstall}
    >
      Instalar
    </Button>
  );
}

const App = (props) => {
  const { loading, alert } = props;

  return (
    <>
      <Loader {...loading} />
      <Alert {...alert} />

      <Routes />

      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    alert: state.alert,
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(App);
