import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

function Home() {
  // console.log("Home");

  let history = useHistory();
  history.replace({ pathname: "/agendamento" });

  return null;
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(Home);
