const faixa = [
    {
        id: "0",
        label: 'Branca'
    },
    {
        id: 1,
        label: 'Cinza / Branca'
    },
    {
        id: 2,
        label: 'Cinza'
    },
    {
        id: 3,
        label: 'Cinza / Preta'
    },
    {
        id: 4,
        label: 'Amarela / Branca'
    },
    {
        id: 5,
        label: 'Amarela'
    },
    {
        id: 6,
        label: 'Amarela / Preta'
    },
    {
        id: 7,
        label: 'Laranja / Branca'
    },
    {
        id: 8,
        label: 'Laranja'
    },
    {
        id: 9,
        label: 'Laranja / Preta'
    },
    {
        id: 10,
        label: 'Verde / Branca'
    },
    {
        id: 11,
        label: 'Verde'
    },
    {
        id: 12,
        label: 'Verde / Preta'
    },
    {
        id: 13,
        label: 'Azul'
    },
    {
        id: 14,
        label: 'Roxa'
    },
    {
        id: 15,
        label: 'Marrom'
    },
    {
        id: 16,
        label: 'Preta'
    }
]

export default faixa;