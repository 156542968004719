import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  TextField,
  Paper,
  Typography,
  Button,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Divider,
  AccordionActions,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReactMarkdown from "react-markdown";
import * as ChatServices from "../../services/chat";
import ReactGA from "react-ga4";
import moment from "moment";
import "moment/min/locales";
moment.locale("pt-br");

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(4),
  },
  questions: {
    padding: theme.spacing(1, 2),
    "& li": {
      marginBottom: theme.spacing(1),
    },
    "& *": {
      wordBreak: "break-word",
    },
  },
  btEnviar: {
    marginTop: theme.spacing(1),
  },
  historico: {
    marginTop: theme.spacing(2),
  },
  historicoTitulo: {
    flexBasis: "80%",
    textTransform: "lowercase",
    fontSize: theme.typography.pxToRem(14),
    "&:first-letter": {
      textTransform: "uppercase",
    },
  },
  historicoData: {
    textAlign: "right",
    fontSize: theme.typography.pxToRem(12),
  },
}));

function Chat({ user }) {
  // console.log(user);
  if (window.location.host === "aluno.academiapedromartinez.com.br")
    ReactGA.send({
      hitType: "pageview",
      page: window.location.hash,
      title: "PandaBot",
    });

  const classes = useStyles();
  const [perguntaRespondida, setPerguntaRespondida] = React.useState("");
  const [question, setQuestion] = React.useState("");
  const [resposta, setResposta] = React.useState();
  const [historico, setHistorico] = React.useState([]);

  // Carrega o histórico do localStorage no início
  React.useEffect(() => {
    const storedHistorico = localStorage.getItem("chatHistorico");
    if (storedHistorico) {
      setHistorico(JSON.parse(storedHistorico));
    }
  }, []);

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const result = await ChatServices.postChat(question);

    const newHistorico = [
      ...historico,
      {
        pergunta: question,
        resposta: result,
        data: moment().format("DD/MM/YYYY HH:mm"),
      },
    ];

    // Salva o histórico no localStorage
    localStorage.setItem("chatHistorico", JSON.stringify(newHistorico));

    setPerguntaRespondida(question);
    setResposta(result);
    setQuestion("");

    // Adiciona a pergunta e resposta ao histórico
    setHistorico(newHistorico);
  };

  // Função para remover um item do histórico
  const removeItemHistorico = (index) => {
    const newHistorico = [...historico];
    newHistorico.splice(index, 1);
    setHistorico(newHistorico);
    localStorage.setItem("chatHistorico", JSON.stringify(newHistorico));
  };

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Typography variant="h5" gutterBottom>
        PandaBot - Beta
      </Typography>

      {!resposta && (
        <div>
          <p>
            Oi, Esse é um recurso totalmente experimental, se encontrar qualquer
            erro, me ajude a evoluir essa ferramenta, me manda um print ;)
          </p>

          <p>
            Usamos tecnologias de Inteligencia Artificial em versão previa,
            então ocasionamente o serviço pode demorar ou oscilar
          </p>

          <p>
            Por exemplo, se você deseja melhorar sua reposição de guarda no
            Jiu-Jitsu, basta pedir ao Pandabot algo como:{" "}
          </p>

          <p>
            "Você poderia me dar algumas dicas de treino para melhorar minha
            reposição de guarda no Jiu-Jitsu, sou [genero], faixa [faixa], tenho
            [idade] anos e [peso]kg?"
          </p>
          <p>
            E voilà! O Pandabot estará pronto para fornecer orientações úteis e
            sugestões de exercícios para você aprimorar suas habilidades.
          </p>
        </div>
      )}

      {resposta && (
        <Paper elevation={2} className={classes.questions}>
          <h2>{perguntaRespondida}</h2>
          <ReactMarkdown>{resposta}</ReactMarkdown>
        </Paper>
      )}

      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          fullWidth
          id="question"
          label="Como posso te ajudar com seu Jiu-Jitsu?"
          value={question}
          onChange={handleQuestionChange}
          required={true}
          multiline
          rows={4}
          variant="outlined"
        />
        <Button
          className={classes.btEnviar}
          id="enviar"
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
        >
          Enviar
        </Button>
      </form>

      {historico.length > 0 && (
        <div className={classes.historico}>
          <Typography variant="h6" gutterBottom>
            Histórico
          </Typography>
          <p>Esse histórico está salvo localmente no seu aparelho</p>
          <List>
            {historico.map((item, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.historicoTitulo}>
                    {item.pergunta}
                  </Typography>
                  <Typography className={classes.historicoData}>
                    {item.data}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <ReactMarkdown>{item.resposta}</ReactMarkdown>
                  </Typography>
                </AccordionDetails>
                <Divider />
                <AccordionActions>
                  {/* <!-- <Button size="small">Cancel</Button> --> */}

                  <IconButton
                    aria-label="delete"
                    onClick={() => removeItemHistorico(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </AccordionActions>
              </Accordion>
            ))}
          </List>
        </div>
      )}
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(Chat);
