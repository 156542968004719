import React from "react";
import InputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";

const MaskedTextField = ({ mask, ...props }) => {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: InputMask,
        inputProps: { mask, placeholderChar: "_" },
      }}
    />
  );
};

export default MaskedTextField;
