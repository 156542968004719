import React from "react";
import * as Autenticacao from "../../services/autenticacao";
import { useForm, Controller } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import { store } from "../../redux/store";
import * as action from "../../redux/actions";
import ReactGA from "react-ga4";

import {
  Container,
  TextField,
  Typography,
  Box,
  Button,
} from "@material-ui/core";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

export default function NovaSenha() {
  if (window.location.host === "aluno.academiapedromartinez.com.br")
    ReactGA.send({
      hitType: "pageview",
      page: window.location.hash,
      title: "Nova Senha",
    });

  const { tipo } = useParams();
  let history = useHistory();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    // console.log("onSubmit", data);

    const response = await Autenticacao.SolicitarNovaSenha(data);

    if (response) {
      store.dispatch(
        action.showAlert({
          message: "Enviado com sucesso",
          type: "success",
          title: "Email",
        })
      );

      history.replace({ pathname: "/" });
    }
  };

  const ListaTipo = {
    "esqueci-minha-senha": {
      titulo: "Esqueci minha senha",
    },
    "primeiro-acesso": {
      titulo: "Primeiro Acesso",
    },
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box mt={2}>
        <Typography component="h1" variant="h5">
          {ListaTipo[tipo].titulo}
        </Typography>

        <Box component="p">
          Problemas para entrar? Insira o seu email e enviaremos um link para
          você ter acessar a sua conta.
        </Box>

        <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
          <Controller
            name="email"
            type="email"
            control={control}
            rules={{
              required: "Campo Obrigatório",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Formato Inválido",
              },
            }}
            defaultValue=""
            render={({ field }) => {
              if (errors.email) {
                field.error = true;
                field.helperText = errors.email.message;
              }

              return (
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Email"
                  autoComplete="email"
                  type="email"
                  required
                  aria-required
                  autoFocus
                  {...field}
                />
              );
            }}
          />

          <Button type="submit" fullWidth variant="contained" color="primary">
            Entrar
          </Button>
        </form>

        <Box mt={3}>
          <Button startIcon={<ArrowBackIcon />} href="#/login">
            Voltar para o Login
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
