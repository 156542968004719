import React, { useEffect, useState } from "react";

import * as ProdutoService from "../../services/produtos.services";

import { Container, Typography, Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import ReactGA from "react-ga4";

const useStyles = makeStyles((theme) => ({
  titulo: {
    padding: "16px 0 0",
    marginBottom: "8px",
  },
  produto: {
    marginBottom: "16px",
  },
}));

const formatCurrency = (value) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
};

function Produtos() {
  if (window.location.host === "aluno.academiapedromartinez.com.br")
    ReactGA.send({
      hitType: "pageview",
      page: window.location.hash,
      title: "Produtos",
    });

  const classes = useStyles();

  const [produtos, setProdutos] = useState();

  useEffect(() => {
    const getProdutos = async () => {
      const produtos = await ProdutoService.getProdutos();

      setProdutos(produtos);
    };

    getProdutos();
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <Typography component="h1" variant="h5" className={classes.titulo}>
        Produtos
      </Typography>

      {produtos && (
        <>
          {produtos.map((produto) => {
            return (
              <Card key={produto.valor} className={classes.produto}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {produto.nome}
                  </Typography>

                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {produto.descricao}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="textSecondary"
                    component="p"
                    align="right"
                  >
                    {formatCurrency(produto.valor)}
                  </Typography>
                </CardContent>
              </Card>
            );
          })}
        </>
      )}
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(Produtos);
