import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import ReactGA from "react-ga4";
import { GoogleOAuthProvider } from "@react-oauth/google";

if (window.location.host === "aluno.academiapedromartinez.com.br")
  ReactGA.initialize("G-C6F1GSZETG");

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#daa520",
      contrastText: "#fff",
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GoogleOAuthProvider clientId="434742810913-i5pokf9058milum0b10t2l2546tcshc1.apps.googleusercontent.com">
        <App />
      </GoogleOAuthProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorkerRegistration.unregister();
reportWebVitals();
