import * as TYPES from "./types";
import { api } from "../../utils/api";

export function setUser(payload) {
  // console.log('setUser', payload);

  localStorage.setItem("user", JSON.stringify(payload));
  api.defaults.headers.common["Authorization"] = `Bearer ${payload.token}`;

  return {
    type: TYPES.USER_SET,
    payload,
  };
}

export function deleteUser() {
  // console.log('deleteUser');

  localStorage.removeItem("user");

  delete api.defaults.headers.common["Authorization"];

  return {
    type: TYPES.USER_DELETE,
  };
}
