import React from "react";
import { Alert as MDAlert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";
// import { AlertWrapper } from "./alert.styled";
import { store } from "../../redux/store";
import * as action from "../../redux/actions";

const Alert = (props) => {
  // console.log("Alert", props);

  const { visible, message, type, title, data } = props;

  const hideAlert = () => {
    store.dispatch(action.hideAlert());
  };

  return visible ? (
    <Snackbar open={visible} autoHideDuration={10000} onClose={hideAlert}>
      <MDAlert
        variant="filled"
        // color={type}
        severity={type}
        onClose={hideAlert}
        className="toast-notification"
      >
        <strong>{title}</strong>&nbsp;
        <span>{message || data?.msg || data?.message}</span>
      </MDAlert>
    </Snackbar>
  ) : null;
};

export default Alert;
